@import "@/assets/scss/variables.scss";

sup {
  font-size: 0.8rem;
  color: $theme-primary-red;
  line-height: 1;
  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '*';
  }
}
