@import "@/assets/scss/variables.scss";

header {
  display: none;
}
@media only screen and (max-width:768px) {
  header {
    display: block;
    position: fixed;
    top:0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    height: 54px;
    padding: 18px 28px;
    box-sizing: border-box;
    border-bottom: 1px solid #F2F4F5;
  }

  .header-backbtn {
    float: left;
  }

  .mobile-header-title {
    width: 100%;
    float: none;
    text-align: center;
    height: 54px;
  }

}
