@import "@/assets/scss/variables.scss";

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  &.sp-align-left {
    justify-content: flex-start;
  }
  &.sp-align-center {
    justify-content: center;
  }
  &.sp-align-right {
    justify-content: flex-end;
  }
  .sp-button {
    + .sp-button {
      margin-left: 16px;
    }
  }
}
