$body-font-size: 16;
$hex-black: 0, 0, 0;
$theme-black: #000000;
$theme-white: #ffffff;
$theme-gray-3: #f7f7f7;
$theme-gray-5: #f2f2f2;
$theme-gray-10: #e5e5e5;
$theme-gray-20: #cccccc;
$theme-gray-50: #808080;
$theme-gray-60: #666666;
$theme-gray-70: #4c4c4c;
$theme-gray-80: #333333;

$theme-primary-red: #e94d4d;
$theme-primary-red-dark-50: #742727;
$theme-primary-red-dark-30: #a33636;
$theme-primary-red-light-50: #f4a6a6;
$theme-primary-red-light-80: #fbdbdb;
$theme-primary-red-light-90: #fdeded;
$theme-primary-red-light-95: #fef6f6;

$theme-primary-green: #47c944;
$theme-primary-green-dark-50: #236422;
$theme-primary-green-dark-30: #328d30;
$theme-primary-green-light-50: #a3e4a1;
$theme-primary-green-light-80: #daf4da;
$theme-primary-green-light-90: #edfaec;

$theme-primary-blue: #4d74ec;
$theme-primary-blue-dark-50: #273a76;
$theme-primary-blue-dark-30: #3651a5;
$theme-primary-blue-light-50: #a6baf6;
$theme-primary-blue-light-80: #dbe3fb;
$theme-primary-blue-light-90: #edf1fd;

$theme-primary-yellow: #ffd600;
$theme-primary-yellow-dark-50: #806b00;
$theme-primary-yellow-dark-30: #b39600;
$theme-primary-yellow-light-50: #ffeb80;
$theme-primary-yellow-light-80: #fff7cc;
$theme-primary-yellow-light-90: #fffbe5;

$theme-dimmed: rgba(0, 0, 0, 0.5);
$theme-disabled: $theme-gray-20;

//:root {
//  --theme-black-opacity-3: rgba(#{$hex-black}, 0.03);
//  --theme-black-opacity-5: rgba(#{$hex-black}, 0.05);
//  @for $i from 1 through 9 {
//    --theme-black-opacity-#{$i * 10}: rgba(#{$hex-black}, 0.5);
//  }
//}

$default-layout-main-gap: 40px;
$default-layout-section-gap: 32px;
$default-layout-title-area: 48px;
$default-layout-search-area: 42px;

.body1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.bg-dimmed {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100dvh;
    align-items: center;
    justify-content: center;
    background-color: $theme-dimmed;
}
