@import "@/assets/scss/variables.scss";

$popup-gap: 32px;
$button-height: 42px;
.popup-wrap {
  position: fixed !important;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  &:not(.bg-transparent) {
    @extend .bg-dimmed;
  }
  .popup-header {
    display: block;
    //display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .popup-container {
    min-width: 216px;
    width: fit-content;
    height: fit-content;
    max-width: calc(100vw - 48px);
    max-height: calc(100vh - 32px);
    padding: $popup-gap;
    background-color: $theme-white;
    border-radius: 8px;
    &.action {
      border-radius: 24px;
    }
    .popup-title {
      display: block;
      align-items: center !important;
      text-align: center;
      margin-bottom: 10px;
    }
    .popup-content {
      // overflow: auto;
      margin: 0 0 24px 0;
      max-width: calc(100vw - ($popup-gap * 2) - 48px);
      max-height: calc(100vh - ($popup-gap * 2) - ($button-height * 2) - (48px * 2));
      word-wrap: break-word;
      text-align: center;
    }
    .description {
      @extend .body1;
      color: $theme-gray-50;
      white-space: pre-line;

      ::v-deep .ul-info {
        line-height:18px;

        li {
          font-weight: 380;
          list-style: outside;
        }

        .content-footer{
          color: #000 !important;
        }
      }
    }
  }
}
