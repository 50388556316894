@import "@/assets/scss/variables.scss";

.sp-button {
  width: 100%;
  &.half-button {
    width: auto;
  }
  > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  &.router-link {
    &.theme-red {
      span {
        color: $theme-primary-red !important;
      }
    }
    span {
      text-decoration: underline;
    }
  }
  &:not(.router-link) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    min-width: 74px;
    padding: 8px 16px;
    height: 36px;
    @extend .body1;
    color: $theme-white;
    text-decoration: none;
    background-color: $theme-black;
    border: 1px solid $theme-black;
    border-radius: 8px;
    cursor: pointer;
    &:not(.sp-outline) {
      &.sp-primary-red {
        border-color: $theme-primary-red;
        background-color: $theme-primary-red;
      }
      &.sp-primary-blue {
        border-color: $theme-primary-blue;
        background-color: $theme-primary-blue;
      }
      &.sp-primary-green {
        border-color: $theme-primary-green;
        background-color: $theme-primary-green;
      }
      &.sp-primary-yellow {
        border-color: $theme-primary-yellow;
        background-color: $theme-primary-yellow;
      }
    }
  }
  &[disabled] {
    color: $theme-disabled;
    background-color: $theme-gray-5;
    border-color: $theme-gray-5;
    pointer-events: none;
    cursor: not-allowed !important;
  }
  &.sp-rounded {
    border-radius: 20px;
  }
  &.sp-outline {
    border: 1px solid $theme-black;
    color: $theme-black;
    background-color: $theme-white;
    &.sp-primary-red {
      border-color: $theme-primary-red;
      color: $theme-primary-red;
    }
    &.sp-primary-blue {
      border-color: $theme-primary-blue;
      color: $theme-primary-blue;
    }
    &.sp-primary-green {
      border-color: $theme-primary-green;
      color: $theme-primary-green;
    }
    &.sp-primary-yellow {
      border-color: $theme-primary-yellow;
      color: $theme-primary-yellow;
    }
    &[disabled] {
      border: 1px solid $theme-disabled;
      color: $theme-disabled;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  &:deep() {
    svg + span,
    span + svg {
      margin: 0 0 0 4px;
    }
  }
  &.icon-right {
    flex-direction: row-reverse;
    svg + span {
      margin: 0 4px 0 0;
    }
  }
}
